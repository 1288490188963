<template>
    <nav class="navbar fixed-top bg-body-tertiary rounded-0" style="padding: 1% 2% 1% 2%;">
        <div class="container-fluid position-relative">
            <!-- Div 1: Alineado al inicio -->
            <button class="menu-icon d-block d-md-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="fa-solid fa-bars"></i>
            </button>

            <div class="d-flex align-items-center">
                <a href="/">
                    <img class="logo-nav" src="/img/icons/logo-nav.svg" alt="logo-mujereal">
                </a>
                
            </div>
     
           <!-- Botón flotante para móviles -->
            <a href="/Contact" class="d-block d-md-none buy-now-mobile" style="text-decoration: none;">
                <button class="cta-button cta-mobile-button">BUY NOW</button>
            </a>
            
            
            <div class="d-none d-md-flex d-flex align-items-center position-absolute start-50 translate-middle-x">
                <a class="links-nav" href="/">HOME</a>
                <div style="position: relative; display: flex; align-items: center;"
                    onmouseover="this.querySelector('div').style.display='block'" 
                    onmouseout="this.querySelector('div').style.display='none'">
                    <a class="links-nav" style="cursor: pointer;">PRODUCTS</a>
                    <div style="display: none; position: absolute; background-color: #F2F2F2; z-index: 1000; padding-bottom: 3vw; margin-left: -0.5vw; margin-top: 11vw; width: 12vw;">
                        <a href="/product/tequila-blanco" 
                        style="padding: 0.5vw 0vw 0 1vw; text-decoration: none !important; color: #4D4D4D; display: block; font-size: 0.7vw;"
                        onmouseover="this.style.color='#EDAC9F'; this.style.backgroundColor='#F2F2F2'" 
                        onmouseout="this.style.color='#4D4D4D'; this.style.backgroundColor='transparent'">
                        TEQUILA BLANCO
                        </a>
                        <a href="/product/tequila-reposado" 
                        style="padding: 0.5vw 0vw 0 1vw; text-decoration: none !important; color: #4D4D4D; display: block; font-size: 0.7vw;"
                        onmouseover="this.style.color='#EDAC9F'; this.style.backgroundColor='#F2F2F2'" 
                        onmouseout="this.style.color='#4D4D4D'; this.style.backgroundColor='transparent'">
                        TEQUILA REPOSADO
                        </a>
                        <a href="/product/tequila-cristalino" 
                        style="padding: 0.5vw 0vw 0 1vw; text-decoration: none !important; color: #4D4D4D; display: block; font-size: 0.7vw;"
                        onmouseover="this.style.color='#EDAC9F'; this.style.backgroundColor='#F2F2F2'" 
                        onmouseout="this.style.color='#4D4D4D'; this.style.backgroundColor='transparent'">
                        TEQUILA CRISTALINO
                        </a>
                        <a href="/product/tequila-anejo" 
                        style="padding: 0.5vw 0vw 0 1vw; text-decoration: none !important; color: #4D4D4D; display: block; font-size: 0.7vw;"
                        onmouseover="this.style.color='#EDAC9F'; this.style.backgroundColor='#F2F2F2'" 
                        onmouseout="this.style.color='#4D4D4D'; this.style.backgroundColor='transparent'">
                        TEQUILA AÑEJO
                        </a>
                    </div>
                </div>


                <a class="links-nav" href="/Story">OUR STORY AND PROCESS</a>
                <a class="links-nav" href="/Contact">BUY NOW</a>
                <a class="links-nav" href="/Contact">CONTACT</a>
            </div>

            <!-- Div 3: Alineado al final -->
            <div class="d-none d-md-flex align-items-center ms-auto icons-m">
                <span class="icon-nav" @click="navigate('https://www.facebook.com/mujerealtequila')" style="cursor:pointer;">
                    <img :src="socialIcons.facebook" @mouseover="changeIcon('facebook', true)" @mouseout="changeIcon('facebook', false)" alt="Facebook" />
                </span>
                <span class="icon-nav" @click="navigate('https://x.com/mujerealtequila')" style="cursor:pointer;">
                    <img :src="socialIcons.iconX" @mouseover="changeIcon('iconX', true)" @mouseout="changeIcon('iconX', false)" alt="Icon X" />
                </span>
                <span class="icon-nav" @click="navigate('https://www.instagram.com/mujerealtequila/?hl=es')" style="cursor:pointer;">
                    <img :src="socialIcons.instagram" @mouseover="changeIcon('instagram', true)" @mouseout="changeIcon('instagram', false)" alt="Instagram" />
                </span>
                <span class="icon-nav" @click="navigate('https://www.tiktok.com/@mujerealtequila')" style="cursor:pointer;">
                    <img :src="socialIcons.tiktok" @mouseover="changeIcon('tiktok', true)" @mouseout="changeIcon('tiktok', false)" alt="TikTok" />
                </span>
            </div>
        </div>
 
        <!-- Offcanvas -->
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            
            <div class="offcanvas-body">
                <div>
                    <a class="links-nav" href="/">HOME</a>               
                </div>
                <div>
                    <a class="links-nav"  @click="toggleProducts" style="cursor:pointer;">PRODUCTS</a>              
                    <transition name="fade">
                        <ul v-show="showProducts" class="list-unstyled ms-3">
                            <li><a class="links-nav op-nv-m" href="/product/tequila-blanco">TEQUILA BLANCO</a></li><br>
                            <li><a class="links-nav op-nv-m" href="/product/tequila-reposado">TEQUILA REPOSADO</a></li><br>
                            <li><a class="links-nav op-nv-m" href="/product/tequila-cristalino">TEQUILA CRISTALINO</a></li><br>
                            <li><a class="links-nav op-nv-m" href="/product/tequila-anejo">TEQUILA AÑEJO</a></li><br>
                        </ul>
                    </transition>
                </div>

                <div>
                    <a class="links-nav" href="/Story">OUR STORY AND PROCESS</a>            
                </div>
                <div>
                    <a class="links-nav" href="/Contact">CONTACT</a>          
                </div>
                <div>
                    <a class="links-nav" href="/Contact">BUY NOW</a>          
                </div>
                <div class="d-flex justify-content-start mb-4">
                    <span class="icon-nav me-4" @click="navigate('https://www.facebook.com/mujerealtequila')" style="cursor:pointer;">
                        <img :src="socialIcons.facebook" @mouseover="changeIcon('facebook', true)" @mouseout="changeIcon('facebook', false)" alt="Facebook" />
                    </span>
                    <span class="icon-nav me-4" @click="navigate('https://x.com/mujerealtequila')" style="cursor:pointer;">
                        <img :src="socialIcons.iconX" @mouseover="changeIcon('iconX', true)" @mouseout="changeIcon('iconX', false)" alt="Icon X" />
                    </span>
                    <span class="icon-nav me-4" @click="navigate('https://www.instagram.com/mujerealtequila/?hl=es')" style="cursor:pointer;">
                        <img :src="socialIcons.instagram" @mouseover="changeIcon('instagram', true)" @mouseout="changeIcon('instagram', false)" alt="Instagram" />
                    </span>
                    <span class="icon-nav" @click="navigate('https://www.tiktok.com/@mujerealtequila')" style="cursor:pointer;">
                        <img :src="socialIcons.tiktok" @mouseover="changeIcon('tiktok', true)" @mouseout="changeIcon('tiktok', false)" alt="TikTok" />
                    </span>
                </div>
            </div>
        </div>

    </nav>
</template>


<script>
export default {
    name: "nav-mujer",
    mounted() {
        window.addEventListener('load', () => {
            document.querySelector('.navbar').classList.add('navbar-visible');
        });
    },
    data() {
        return {
            socialIcons: {
                facebook: '/img/icons/icon-facebook.svg',
                iconX: '/img/icons/icon-iconX.svg',
                instagram: '/img/icons/icon-instagram.svg',
                tiktok: '/img/icons/icon-tiktok.svg',
            },
            hoverIcons: {
                facebook: '/img/icons/icon-facebook-black.svg',
                iconX: '/img/icons/icon-x-black.svg',
                instagram: '/img/icons/icon-instagram-black.svg',
                tiktok: '/img/icons/icon-tiktok-black.svg',
            },
            showProducts: false,
        };
    },
    methods: {
        navigate(url) {
            window.open(url, '_blank'); // Redirige a la URL
        },
        changeIcon(iconName, isHover) {
            this.socialIcons[iconName] = isHover ? this.hoverIcons[iconName] : `/img/icons/icon-${iconName}.svg`;
        },
        toggleOffcanvas() {
        this.isMenuActive = !this.isMenuActive; // Cambia el estado del botón de menú
    },
    toggleProducts() {
        this.showProducts = !this.showProducts; // Alterna la visibilidad de la lista
    },
    },
};
</script>