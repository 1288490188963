<template>
    <div v-if="visible" class="preloader" id="preloader">
      <div class="logo">
        <img src="/img/icons/logo-nav.svg" alt="Logo" class="fade-in" />
      </div>
      
      <div class="dots">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Preloader',
    data() {
      return {
        visible: true 
      };
    },
    mounted() {

      setTimeout(this.hidePreloader, 2000);
    },
    methods: {
      showPreloader() {
        this.visible = true;
      },
      hidePreloader() {
        this.visible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9); 
    z-index: 9999;
  }
  
  .logo {
    margin-bottom: 20px; 
  }
  

  .fade-in {
    animation: fadeIn 1s ease-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .logo img {
    width: 120px;
    height: auto;
  }
  
  .dots {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
 
  .dot {
    width: 10px;
    height: 10px;
    background-color: #a9715c;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1.2);
    }
  }
  
  @media only screen and (max-width: 768px) {
    .preloader {
      background-color: rgba(255, 255, 255, 1); 
    }
  
    .dots {
      gap: 5vw;
    }
  
    .dot {
      width: 10px;
      height: 10px;
    }
  
    .logo img {
      width: 30vw; 
    }
  }
  </style>
  