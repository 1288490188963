/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './js/scripts.js';

import './bootstrap';
import { createApp } from 'vue';
import ExampleComponent from './components/ExampleComponent.vue';
import VideoComponent from './components/VideoComponent.vue';
import NavComponent from './components/navComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import InstagramComponent from './components/InstagramComponent.vue';
import Preload from './components/Preload.vue';

const app = createApp({
});

app.component('example', ExampleComponent);
app.component('video-component', VideoComponent);
app.component('nav-mujer', NavComponent);
app.component('footer-mujereal', FooterComponent);
app.component('insta-post', InstagramComponent);
app.component('Preloader', Preload);

app.mount('#app');