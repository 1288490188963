<template>
    <div class="video-section">
    <div class="video-content">
        <div class="video-wrapper2">
        <iframe
            src="https://www.youtube.com/embed/v0NBatBqJ3U?autoplay=1&mute=1&controls=0&loop=1&playlist=v0NBatBqJ3U"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            >
        </iframe>
        </div>
        <div class="overlay-video-content">
            <h2 class="video-title">
    <span class="bold-text">MUJEREAL</span> <span class="regular-text">TEQUILA</span>
</h2>
            <button class="video-button" @click="navigateToStory">OUR STORY AND PROCESS</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "video-component",
  methods: {
    navigateToStory() {
      window.location.href = "/Story"; // Cambia "/story" por la URL deseada
    },
  },
};
</script>