<template>
    <div>
        <p>This is an example of a new component in VueJs</p>
    </div>
</template>

<script>
export default {
    name: "example",
}
</script>

<style scoped>

</style>
