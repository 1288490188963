<template>
    <div class="instagram-section">
        <div class="instagram-title"> 
            <img src="/img/insta.svg" alt="Instagram Icon" class="instagram-icon">
            <h2>Follow us on Instagram</h2>
        </div>

        <div class="d-none d-md-block">
            <div class="instagram-container">
            </div>
        </div>
       
        <div id="carouselExample" class="carousel carousel-instagram slide d-block d-md-none">
            <div class="carousel-inner">
                <!-- Las imágenes se cargarán dinámicamente aquí -->
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        
    </div>
</template>

<script>
document.addEventListener("DOMContentLoaded", function() {
    const accessToken = 'IGQWRPei1aR1VYcjFkQVUySkRLV1g1VTFaVHExVXlJQWhqQW1mdVR3Qi1TZAmtDQlFHNnprbmYyQ2xHWVBHT0VMRHR5SGpzQ0cwNWVBWmZAWNDBibnpOQnRJRk1zNHotVHRPakZA0aU00QzY3YUFYeUJIRVFZAN3ZAIOG8ZD';
    const container = document.querySelector('.instagram-container');

    fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type&access_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
            data.data.slice(0, 3).forEach(post => {
                const postElement = document.createElement('div');
                postElement.classList.add('instagram-post');

                // Verifica el tipo de medio y crea el elemento adecuado
                if (post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM') {
                    postElement.innerHTML = `<a href="${post.permalink}" target="_blank">
                        <img src="${post.media_url}" alt="${post.caption}">
                    </a>`;
                } else if (post.media_type === 'VIDEO') {
                    postElement.innerHTML = `<a href="${post.permalink}" target="_blank">
                        <video controls>
                            <source src="${post.media_url}" type="video/mp4">
                        </video>
                    </a>`;
                }

                container.appendChild(postElement);
            });
        })
        .catch(error => console.error('Error fetching Instagram posts:', error));
});
</script>
