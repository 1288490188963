document.addEventListener("DOMContentLoaded", function () {
    const accessToken = 'IGQWRPei1aR1VYcjFkQVUySkRLV1g1VTFaVHExVXlJQWhqQW1mdVR3Qi1TZAmtDQlFHNnprbmYyQ2xHWVBHT0VMRHR5SGpzQ0cwNWVBWmZAWNDBibnpOQnRJRk1zNHotVHRPakZA0aU00QzY3YUFYeUJIRVFZAN3ZAIOG8ZD';
    const carouselInner = document.querySelector("#carouselExample .carousel-inner");
    const carouselElement = document.querySelector("#carouselExample");

    fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type&access_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
            const posts = data.data.slice(0, 4); // Obtener las primeras 4 publicaciones

            posts.forEach((post, index) => {
                const carouselItem = document.createElement("div");
                carouselItem.classList.add("carousel-item");
                if (index === 0) {
                    carouselItem.classList.add("active"); // La primera imagen debe estar activa
                }

                if (post.media_type === "IMAGE" || post.media_type === "CAROUSEL_ALBUM") {
                    carouselItem.innerHTML = `
                        <a href="${post.permalink}" target="_blank">
                            <img src="${post.media_url}" class="d-block w-100" alt="${post.caption || 'Instagram Post'}">
                        </a>`;
                } else if (post.media_type === "VIDEO") {
                    carouselItem.innerHTML = `
                        <a href="${post.permalink}" target="_blank">
                            <video class="d-block w-100" muted>
                                <source src="${post.media_url}" type="video/mp4">
                            </video>
                        </a>`;
                }

                carouselInner.appendChild(carouselItem);
            });

            // Agregar el evento para manejar la reproducción automática
            carouselElement.addEventListener("slid.bs.carousel", function () {
                const activeItem = carouselInner.querySelector(".carousel-item.active");
                const videos = carouselInner.querySelectorAll("video");

                // Pausar todos los videos primero
                videos.forEach(video => video.pause());

                // Reproducir el video en el elemento activo si existe
                const activeVideo = activeItem.querySelector("video");
                if (activeVideo) {
                    activeVideo.play();
                }
            });
        })
        .catch(error => console.error("Error fetching Instagram posts:", error));
});
