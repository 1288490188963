<template>

<footer class="footer">
    <div class="footer-contact">
        <a class="footer-mail" href="mailto:contact@mujereal.com"> 
        <span><i class="fa-regular fa-envelope"></i> contact@mujereal.com</span>
        </a>        
        <div class="footer-socials">
            <a href="https://www.facebook.com/mujerealtequila" target="_blank"><i class="fab fa-facebook-f"></i></a>
            <a href="https://x.com/mujerealtequila" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
            <a href="https://www.instagram.com/mujerealtequila/?hl=es" target="_blank"><i class="fab fa-instagram"></i></a>
            <a href="https://www.tiktok.com/@mujerealtequila" target="_blank"><i class="fab fa-tiktok"></i></a>
            <span>@mujerealtequila</span>
        </div>
    </div>
    <div class="footer-divider d-none d-md-block">
        <img src="/img/divider_footer.svg" alt="Divider">
    </div>

    <div class="footer-divider d-block d-md-none">
        <img src="/img/story_line.svg" alt="Divider">
    </div>

    <div class="footer-bottom">
        <div class="footer-arrow">
            <a href="#"> <img src="/img/arrow_top.svg" alt="top"> </a>
        </div>
        <p>© 2024 All right reserved MUJEREAL LLC | <a href="#">Privacy</a> <a href="#">Terms</a></p>
        <div class="footer-developed">
            <!--a href="https://www.zipvisual.com/es" target="_blank"> <img src="/img/zip.svg" alt="ZIP Logo"></a-->
        </div>
    </div>
</footer>



</template>

<script>
export default {
  name: "footer-mujereal",
};
</script>